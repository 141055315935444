<template>
    <v-container class="py-3 px-6" style="min-height: 100vh;">
        <v-card-text class="page-title d-block d-lg-none pt-2 pb-0 pr-0">{{ $route.meta.name }}</v-card-text>
        <v-breadcrumbs class="pb-0 mb-0 pt-3 mr-0 pr-0" :items="items"></v-breadcrumbs>
        <RemainingSessions></RemainingSessions>
    </v-container>
</template>

<script>

    import RemainingSessions from '@/components/clients/RemainingSessions';
    export default {
        components: {RemainingSessions},
        data() {
            return {
                items: [
                    {
                        text: this.$t('$vuetify.ClientNavItem.dashboard'),
                        disabled: false,
                        to: `/${this.$route.params.lang}/panel/dashboard`,
                    },
                    {
                        text: this.$t('$vuetify.sessionsPage.tabs.remainingSessions'),
                        disabled: true,
                        to: `/${this.$route.params.lang}/panel/sessions`,
                    }
                ]
            }
        },
        created() {

        },

        methods: {},
        watch: {}
    }
</script>

<style scoped>

</style>